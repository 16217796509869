@import "Globals.css";

.educationPageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}

.educationContainer {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-bottom: 20px;
}

.educationContainer:last-child {
    margin-bottom: 0;
}

.educationTitle {
    background: var(--colorE);
    margin: 0;
    padding: 5px;
    width: 100%;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.educationInstitution {
    margin-right: 5px;
}

.educationDates {
    font-size: 16px;
    font-style: italic;
}

.educationDescription {
    padding: 5px;
    width: 100%;
    border-left: 2px solid var(--colorE);
    border-bottom: 2px solid var(--colorE);
    border-right: 2px solid var(--colorE);
}

.educationFocusItem {
    margin-bottom: 5px;
}

.educationFocusItem:last-child {
    margin-bottom: 0;
}