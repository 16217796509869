.pageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.mainBodyContainer {
    max-width: 1500px;
    width: 100%;
    margin-top: 20px;
}

.bodyParagraph {
    margin: 0 0 10px;
    font-size: 16px;
    text-align: left;
    width: 100%;
    max-width: 1000px;
}

.bodyParagraph:last-child {
    margin-bottom: 0;
}

.pageNotFoundMessageContainer {
    text-align: center;
    font-size: 24px;
}