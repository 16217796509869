:root {
    --oldBgColor: #73a2d1;

    --colorA: #112f3c;
    --colorB: #93DAF9;
    --colorC: #3ebdf4;
    --colorD: #163e50;
    --colorE: #1c4e63;

    --linkColor: #E08F31;

    --fontSize: 16px;
}

a {
    color: var(--linkColor);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--colorA);
    color: var(--colorB);
    font-size: 16px;
    padding: 5px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
    font-size: var(--fontSize);
}

h1, h2, div {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
