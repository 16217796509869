@import "Globals.css";

@media screen and (min-width: 1050px) {
    .experiencePageContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .workExperienceSectionContainer {
        display: inline-flex;
        flex-direction: column;
        align-items: baseline;
        max-width: 500px;
        width: 100%;
        height: 400px;
        border: 2px solid var(--colorE);
        margin-right: 20px;
    }

    .workExperienceSectionContainer:last-child, .technicalExperienceSectionContainer:last-child {
        margin-right: 0;
    }

}

@media screen and (max-width: 1049.999px) {
    .experiencePageContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
    }

    .workExperienceSectionContainer {
        display: inline-flex;
        flex-direction: column;
        align-items: baseline;
        max-width: 500px;
        width: 100%;
        height: 400px;
        border: 2px solid var(--colorE);
        margin-bottom: 20px;
    }

    .workExperienceSectionContainer:last-child, .technicalExperienceSectionContainer:last-child {
        margin-bottom: 0;
    }
}

.workExperienceSectionTitle {
    font-size: 24px;
    margin: 0;
    padding: 5px;
    background: var(--colorE);
    width: 100%;
}

.workExperienceDisplayContainer {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 100%;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.technicalExperienceDisplayContainer {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 100%;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.workExperienceTabContainer {
    background: var(--colorD);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-y: auto;
}

.workExperienceTabSelected {
    background: var(--colorA);
    font-size: 16px;
    padding: 5px;
    text-align: left;
}

.workExperienceTab {
    font-size: 16px;
    padding: 5px;
    text-align: left;
}

.workExperienceTab:hover, .workExperienceTabSelected:hover {
    cursor: pointer;
    background: var(--colorA);
}

.workExperienceDescriptionContainer {
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    overflow-y: auto;
    width: 100%;
}

.workExperienceDescriptionTitle {
    font-size: 18px;
    margin: 0 0 5px;
    color: var(--linkColor);
}

.workExperienceDates {
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
    white-space: nowrap;
    color: #E08F31;
    margin-bottom: 5px;
}

.workExperienceDescriptionBody {
    font-size: 14px;
    text-align: left;
}

.technicalExperienceSubtitle {
    margin: 0;
    padding: 5px;
    font-weight: normal;
    color: var(--linkColor);
}

.technicalExperienceListItem {
    padding: 5px;
    width: 100%;
}

.technicalExperienceClickableListItem  {
    padding: 0;
    width: 100%;
}

.technicalExperienceClickableListItemTitle  {
    width: 100%;
    padding: 5px;
}

.technicalExperienceClickableListItemTitle:hover {
    cursor: pointer;
    background: var(--colorD);
}


.technicalExperienceSubsection {
    background: var(--colorD);
    width: 100%;
    padding: 5px;
}

.technicalExperienceSubsectionItem {
    margin-bottom: 5px;
}

.technicalExperienceSubsectionItem:last-child {
    margin-bottom: 0;
}

.technicalExperiencePatentSubtitle {
    font-size: 16px;
    font-weight: normal;
    font-style: italic;
    margin: 0 0 5px;
    color: var(--colorC);
}
