.contactInfoBody {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: fit-content;
    margin: 0 auto;
    padding: 10px;
}

.contactInfoContainer {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    font-size: 16px;
}

.emailContainer {
    margin-bottom: 10px;
}

.emailLink {
    margin-right: 5px;
}

.emailCopyIcon:hover {
    cursor: pointer;
}

.emailCopySuccessIndicator {
    color: green;
    margin-left: 5px;
}

.emailCopyFailureIndicator {
    color: red;
    margin-left: 5px;
}