@import "Globals.css";

.headerContainer {
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0;
}

.mainTitle {
    font-size: 36px;
    padding: 0;
    margin: 0;
}

.subTitle {
    font-size: 24px;
    margin: 5px 0 0;
}

.lowerHeaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0 0;
    font-size: 18px;
}

.sectionTabSpacer {
    background: var(--colorB);
    height: 3px;
    flex-grow: 1;
}

.sectionTabsContainer {
    margin: 0 15px;
}

.selectedSectionTab {
    margin-right: 15px;
    flex-grow: 0;
    /*text-decoration: none;*/
    font-weight: bold;
    color: var(--colorB);
}

.sectionTab {
    margin-right: 15px;
    flex-grow: 0;
    text-decoration: none;
    font-weight: bold;
    color: var(--colorB);
}

.sectionTab:hover {
    text-decoration: underline;
    cursor: pointer;
}

.sectionTab:last-child, .selectedSectionTab:last-child {
    margin-right: 0;
}